export default defineNuxtRouteMiddleware((to, _from) => {
  console.log("[Middleware] noAuth")

  // if (process.client) return;

  const store = useStore()

  // console.log(user.value);

  // if (user.value) {
  //   console.log('user found')
  // }

  if (store.isLoggedIn) {
    console.log("[Middleware] noAuth: User found")

    if (to.path !== "/") {
      return navigateTo("/")
    }
  }
  else {
    console.log("No user found found")
  }
})
